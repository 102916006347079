<script src="../../main.js"></script>
<template>
  <div class="order-box">
    <van-nav-bar
      title="盘库"
      left-text="返回"
      right-text="取消盘库"
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    />
    <div class="content">
      <div class="content-data">
        <h3>盘库数据</h3>
        <div class="content-data-four">
          <div>
            <div>{{dataOnline.storeNum}}</div>
            <div>库存数</div>
          </div>
          <div>
            <div>{{dataOnline.checkNum}}</div>
            <div>已扫描</div>
          </div>
          <div>
            <div>{{dataOnline.waitNum}}</div>
            <div>待扫描</div>
          </div>
          <div>
            <div>{{dataOnline.errorNum}}</div>
            <div>异常数</div>
          </div>
        </div>
      </div>
      <van-tabs  sticky color="#3D69E1" class="tabs" v-model="active" v-if="!isError" >
        <van-tab title="库存清单"></van-tab>
        <van-tab :title="'异常库存('+checkErrorList.length+')'"></van-tab>
      </van-tabs>
      <div class="content-center" v-if="active===0&&!isError" >
        <div class="tips">库存清单展示所选盘库类的全部在库库存！</div>
        <div class="content-text" v-if="list.length!==0">
          <div class="content-text-item" v-for="(item,index) in list" :key="index" @click="clickStoreDetail(item)">
            <div class="commodity">
              <p class="serial">
                <span class="left">{{item.skuGoodsName}}</span>
                <span class="right">{{item.checkNum}}/{{item.storeNum}} <van-icon name="arrow-down" v-if="!item.active"/><van-icon name="arrow-up" v-else/></span>
              </p>
              <div v-for="(item2,index) in item.storeNoList" :key="index" class="child" v-show="item.active">
                <div>
                  <van-icon name="checked" color="#1989fa" v-if="item2.checkFlag!==0"/>
                  <van-icon name="checked" v-else/>
                  <span>{{item2.storeNo}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="empty" v-if="list.length===0">请先扫描库存码</div>
      </div>
      <div class="content-center" v-if="active===1&&!isError">
        <div class="tips">异常库存内展示待扫描以及多出的库存详情！</div>
        <div class="content-abnormal" v-for="(item,index) in checkErrorList" :key="index">
          <!--<div class="left">排气 | 头段排气 | HSJ | ZR-125 CG2021122503-FJXMJF-LG-016</div>
          <div class="right">盘盈</div>-->
          <div class="left">{{item.skuGoodsName+"|a"+item.storeNo}}</div>
          <div class="right">{{item.errorName}}</div>
        </div>
      </div>
      <div class="content-center" v-if="isError">
        <h3>异常处理</h3>
        <div class="content-error" v-for="(item,index) in resultErrorList" :key="index">
          <div class="content-header">
            <!--<div class="left">排气 | 头段排气 | HSJ | ZR-125 CG2021122503-FJXMJF-LG-016</div>
            <div class="right">盘盈</div>-->
            <div class="left">{{item.skuGoodsName+"|a"+item.storeNo}}</div>
            <div class="right">{{item.errorName}}</div>
          </div>
          <div class="content-detail">
              <p>异常类型： {{item.errorName}}</p>
              <p>库存状态： {{item.storeStatusName}}</p>
              <p>处理流程： {{item.dealWay}}</p>
              <p>处理结果：{{item.dealResult}} </p>
          </div>
          <div class="button">
            <van-button style="padding: 0 3px"  color="#3D69E1"  plain type="normal" v-if="((item.errorCode===1&&item.storeStatus===1)||(item.errorCode===1&&item.storeStatus===2) || (item.errorCode===1&&item.storeStatus===7))&&!item.dealResult" @click="scan('error',item)">扫货架码</van-button>
            <van-button style="padding: 0 3px"  color="#3D69E1"  plain type="normal" v-if="item.errorCode===2&&item.storeStatus===2&&!item.dealResult" @click="handleDialog(item)">处理</van-button>
            <van-button style="padding: 0 3px"  color="#3D69E1"  plain type="normal" disabled v-if="(item.errorCode===4&&item.storeStatus===4)||(item.errorCode===5&&item.storeStatus===5)">无法处理</van-button>
            <van-button style="padding: 0 3px"  color="#3D69E1"  plain type="normal" v-if="item.errorCode===2&&item.storeStatus===2&&item.dealResult" @click="handleDialog(item)">重新处理</van-button>
            <van-button style="padding: 0 3px"  color="#3D69E1"  plain type="normal" v-if="((item.errorCode===1&&item.storeStatus===1)||(item.errorCode===1&&item.storeStatus===2))&&item.dealResult"  @click="scan('error',item)">重新处理</van-button>
          </div>
        </div>
        <div class="empty" v-if="resultErrorList.length===0">暂无异常</div>
      </div>
      <div class="notarize" v-if="!isError">
        <button @click="scan('normal')">扫描</button>
        <button @click="submitGoodsCheck()">提交盘库</button>
      </div>
      <div class="notarize-bottom" v-if="isError&&resultErrorList.length!==0">
        <button @click="isError=false">返回</button>
        <button @click="submitErrorDealResult()">提交异常</button>
      </div>
      <van-dialog v-model="show" title="备注" show-cancel-button @confirm="confirm"   :before-close="beforeClose">
        <div class="dialog">
          <input class="dialog-remark" v-model="handleInput" placeholder="请输入备注&结果" type="text" />
        </div>
      </van-dialog>
    </div>
  </div>
</template>

<script>
import { updateGoodsCheckStatus,getSkuGoodsCheckDetail,queryStockNoCheckInfo,submitSkuGoodsStockByStoreNo,getErrorStoreList,submitGoodsCheck,getStoreDataOnline,getGoodsCheckEventErrorByPage,submitErrorDealResult} from '../../service/inventory'
import { wxScan} from '../../service/ware'
import navBar from '@/components/NavBar';
import navTop from '@/components/NavTop';
import {Toast} from "vant";
export default {
  data(){
    return{
      shelfCode:'请扫码货架码',
      list:[],
      storeNos:[],//库存编码
      active:0,
      checkEventId:'',
      storeNoList:[],
      checkErrorList:[],
      resultErrorList:[],
      isError:false,
      show:false,
      dataOnline:{
        checkNum:0, //扫描数
        waitNum:0,//待扫描
        errorNum:0, //异常数
        storeNum:0,//库存数
      },
      dialogId:'',//弹框对应的时间
      handleInput:""
    }
  },
  components:{
    navBar,
    navTop
  },
  mounted() {
    this.checkEventId=this.$route.query.checkEventId;
    this.getStoreDataOnline();
    this.getSkuGoodsCheckDetail();
    this.getErrorStoreList();
    this.getGoodsCheckEventErrorByPage();
  },
  methods:{
    async submitErrorDealResult(){
      const res = await submitErrorDealResult({checkEventId:this.checkEventId,errorDealJa:this.resultErrorList});
      if(res.code===0){
        Toast({message:"处理异常成功",duration:3000});
        this.$router.push({path:'/success',query:{str:'盘库'}})
      }else{
        Toast({message:res.message,duration:3000})
      }
    },
    beforeClose(action, done) {
      if (action === 'confirm') {
        if(this.handleInput===""){
          Toast({message:"请输入备注内容",duration:3000})
          return done(false);
        }else{
          done();
        }
      } else {
        done();
      }
    },
    confirm(){
      let _this =this;
      this.resultErrorList.forEach(function(item){
         if(item.id === _this.dialogId){
            item.dealResult = _this.handleInput;
        }
      })
    },
    handleDialog(item){
       this.dialogId = item.id;
       this.show =true;
    },
    async  getGoodsCheckEventErrorByPage(){
      const res = await getGoodsCheckEventErrorByPage({checkEventId:this.checkEventId});
      if(res.code===0){
        this.resultErrorList =res.data.list;
        console.log(this.resultErrorList)
        console.log('this.resultErrorList')
      }else{
        Toast({message:res.message,duration:3000})
      }
    },
    async getErrorStoreList(){
      const res = await getErrorStoreList({checkEventId:this.checkEventId});
      if(res.code===0){
        this.checkErrorList =res.data;
      }else{
        Toast({message:res.message,duration:3000})
      }
    },
    async getStoreDataOnline(){
      const res = await getStoreDataOnline({checkEventId:this.checkEventId});
      if(res.code===0){
        this.dataOnline =res.data;
      }else{
        Toast({message:res.message,duration:3000})
      }
    },
    async getSkuGoodsCheckDetail(){
      const res = await getSkuGoodsCheckDetail({checkEventId:this.checkEventId});
      if(res.code===0){
          res.data.forEach(item => {
             item.active = false;
          })
          this.list =res.data;
          console.log(this.list);
      }else{
        Toast({message:res.message,duration:3000})
      }
    },
    async updateGoodsCheckStatus(param){
      const res = await updateGoodsCheckStatus(param);
      if(res.code===0){
        this.$router.push({path:'/inventory-list'})
      }else{
        Toast({message:res.message,duration:3000})
      }
    },
    async queryStockNoCheckInfo(skuGoodsId,item){
      const res = await queryStockNoCheckInfo({skuGoodsId:skuGoodsId,checkEventId:this.checkEventId});
      if(res.code===0){
        item.active = !item.active
        item.storeNoList = res.data;
      }else{
        Toast({message:res.message,duration:3000})
      }
    },
    async submitSkuGoodsStockByStoreNo(storeNo){
      let that = this;
      const res = await submitSkuGoodsStockByStoreNo({storeNo:storeNo,checkEventId:this.checkEventId});
      if(res.code===0){
          that.list.forEach(function(item,index){
                if(item.skuGoodsId === res.data.skuGoodsId){
                    item.checkNum = item.checkNum+1;
                    item.active = false;
                    that.queryStockNoCheckInfo(res.data.skuGoodsId,item);
                }
            })
          that.getStoreDataOnline();//更新扫描数据
          that.getErrorStoreList();//更新异常库存
      }else{
        Toast({message:res.message,duration:3000})
      }
    },
    async submitGoodsCheck(){
      Toast.loading({
        message: '加载中...',
        forbidClick: true
      });
      const res = await submitGoodsCheck({checkEventId:this.checkEventId});
      Toast.clear();
      if(res.code===0){
        this.list = [];
        if(!res.data){
          Toast.success({message:"盘库成功",duration:2000})
          this.$router.push({path:'/success',query:{str:'盘库'}})
        }else{
          this.getGoodsCheckEventErrorByPage();
          this.isError =true;
        }
      }else{
        Toast({message:res.message,duration:3000})
      }
    },
    clickStoreDetail(item){
      this.queryStockNoCheckInfo(item.skuGoodsId,item);
    },
    onClickLeft(){
      let param={
        checkEventId:this.$route.query.checkEventId
      }
      this.updateGoodsCheckStatus(param);
    },
    onClickRight(){
      let param={
        checkEventId:this.$route.query.checkEventId
      }
      this.updateGoodsCheckStatus(param);
    },
    async wxAuthConfig(name){
      let params ={
        url:location.href
      }
      Toast.loading({
        message: '加载中...',
        forbidClick: true
      });
      let _this = this;
      const { data } = await wxScan(params)
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: data.appId, // 必填，公众号的唯一标识
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.nonceStr, // 必填，生成签名的随机串
        signature: data.signature,// 必填，签名，见附录1
        jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
      });
      Toast.clear();
      wx.ready(function () {
        wx.scanQRCode({
          needResult:1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
          scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有
          success: function (res) {
            var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
            if(name==="error"){
              if(result.includes('HJ')){
                _this.resultErrorList.forEach(function(item){
                  if(item.id === _this.dialogId){
                    item.dealResult = `绑定货架码 ${result}排气`;
                    item.shelvesCode = result;
                  }
                })
              }else{
                Toast({message:"扫描错误，请重新扫描库存码！",duration:3000})
              }
            }else{
              if(result.includes('HJ')&&!result.includes('CG')){
                _this.shelfCode = result;
              }else if(result.includes('CG')){
                _this.submitSkuGoodsStockByStoreNo(result);
              }else{
                Toast({message:"扫描错误，请重新扫描！",duration:3000})
              }
            }
          }
        });
      })
      wx.error(function(){
         location.reload();
      })
    },
    clear(str){
      if(str==="HJ"){
         this.shelfCode = "请扫码货架码";
      }else{
         this.storeNos = [];
         this.list = [];
      }
    },
    jump(){
      let str = 'in'
      this.$router.push({path:'/success',query:{str:str}})
    },
    scan(name,item){
      if(name==='error'){
        this.dialogId = item.id;
      }
      this.wxAuthConfig(name);
    },
  }
}
</script>
<style lang="less" scoped>
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@import '../../common/style/mixin';
.dialog{
  text-align: center;
  .dialog-remark{
    height: 80px;
    padding-left: 20px;
    background-color: #E2E2E2;
    width: 80%;
    margin: 20px;
  }
}

.tabs{
  margin-top: 20px;
}
.notarize{
  position: fixed;
  bottom: 160px;
  width: 534px;
  left: 50%;
  transform: translateX(-50%);
  button{
    height: 80px;
    border: 2px solid #3D69E1;
    border-radius: 40px;
    opacity: 1;
  }
  button:nth-of-type(1){
    width: 200px;
    background: #E7EBF8;
    color: #3D69E1;
    margin-right: 134px;
  }
  button:nth-of-type(2){
    width: 200px;
    background: #3D69E1;
    color: #FFFFFF;
  }
}
.notarize-bottom{
  margin: 100px 0 50px 40px;
  padding-bottom: 50px;
  width: 534px;
  button{
    height: 80px;
    border: 2px solid #3D69E1;
    border-radius: 40px;
    opacity: 1;
  }
  button:nth-of-type(1){
    width: 200px;
    background: #E7EBF8;
    color: #3D69E1;
    margin-right: 134px;
  }
  button:nth-of-type(2){
    width: 200px;
    background: #3D69E1;
    color: #FFFFFF;
  }
}
.empty{
   text-align: center;
}
.content{
  padding: 48px 60px 0 64px;
  .content-data{
    .content-data-four{
      border:1px solid #ececec;
      padding: 20px;
      font-size: 26px;
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      text-align: center;
      div{
        div:nth-of-type(2){
          margin-top: 15px;
        }
      }
    }
  }
  .content-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .content-title-left{
      height: 42px;
      font-size: 32px;
      font-family: Yu Gothic UI;
      font-weight: bold;
      color: #000000;
      opacity: 1;
    }
    .content-title-right{
      button{
        width: 136px;
        height: 60px;
        border-radius: 40px;
        opacity: 1;
      }
      button:nth-of-type(1){
        background: #B74134;
        color: #fff;
      }
    }
  }
  .content-center{
    .tips{
      background: #dbe5f9;
      color: #3D69E1;
      height: 40px;
      line-height: 40px;
      text-align: center;
    }
    margin-top: 40px;
    .content-text{
      //height: 600px;
      overflow-y: auto;
      margin-top: 44px;
      }
   }
  .content-text-item{
    //display: flex;
    align-items: center;
    border-bottom: 2px solid #F4F4F4;
    }
    .content-abnormal{
      margin: 0 30px;
      display: flex;
      flex-flow:row nowrap;
      height: 120px;
      align-items: center;
      border-bottom: 1px solid #ececec;
      word-break: break-all;
      .left{
        width: 60%;
      }
      .right{
        text-align: right;
        flex: 1;
      }
    }
  .content-error{
    padding-top: 15px;
    border: 1px solid #ececec;
    margin-top: 15px;
    .content-header{
      margin: 0 30px;
      display: flex;
      flex-flow:row nowrap;
      height: 120px;
      align-items: center;
      border-bottom: 1px solid #ececec;
      word-break: break-all;
      .left{
        width: 60%;
      }
      .right{
        text-align: right;
        flex: 1;
      }
    }
    .content-detail{
      margin: 0 30px;
      padding-top: 10px;
      p{
        line-height: 50px;
      }
    }
    .button{
      text-align: right;
      padding-bottom: 10px;
      padding-right: 10px;
    }
  }
  }
  .empty{
    margin-top: 24px;
    text-align: center;
    color: #9FA2A8;
    font-weight: 400;
    font-size: 24px;
    padding: 18px;
    align-items: center;
  }
  .Shelf-code{
    margin-top: 20px;
    height: 80px;
    background: #F4F4F4;
    border: 2px solid #F4F4F4;
    opacity: 1;
    border-radius: 0px;
    padding: 20px;
    font-size: 28px;
    font-family: Yu Gothic UI;
    font-weight: bold;
    color: #393C41;
    opacity: 1;
  }
  .active{
    .index{
      background: #9FA2A8;
      border: 2px solid #9FA2A8;
      opacity: 1;
    }
  }
.index{
  width: 40px;
  height: 40px;
  background: #000000;
  color: #FFFFFF;
  text-align: center;
  line-height: 40px;
  align-items: center;
  margin-right:30px;
  opacity: 1;
}
.commodity{
  .serial{
    font-size: 26px;
    color: #393C41;
    opacity: 1;
    text-align: left;
    display: flex;
    flex-flow:row nowrap;
    height: 70px;
    align-items: center;
    .left{
      width: 80%;
    }
    .right{
      text-align: right;
      flex: 1;
    }
  }
  .child{
    height: 50px;
    line-height: 50px;
    font-size: 26px;
    vertical-align: middle;
    padding-left: 10px;
    div{
      &>span{
        padding-left: 10px;
      }
    }
  }
}
.hint{
  display: flex;
}

</style>
