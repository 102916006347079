<template>
  <div class="order-box">
    <nav-top :title="'入库'"></nav-top>
    <div class="content">
      <div class="content-top">
        <div class="content-title">
          <div class="content-title-left">
            <p>货架码</p>
          </div>
          <div class="content-title-right" v-if="shelfCode.includes('HJ')">
            <button @click="clear('HJ')">清空</button>
          </div>
        </div>
        <div class="Shelf-code">{{shelfCode}}</div>
      </div>
      <div class="content-center">
        <div class="content-title">
          <div class="content-title-left">
            <p>库存码</p>
          </div>
          <div class="content-title-right" v-if="list.length!==0">
            <button @click="clear('CJ')">清空</button>
          </div>
        </div>
        <div class="content-text" v-if="list.length!==0">
          <div class="content-text-item" v-for="(item,index) in list" :key="index">
            <p class="index">{{index + 1}}</p>
            <div class="commodity">
              <p class="serial">{{ item.storeNo}}</p>
              <p>{{item.categoryName+' | '+item.goodsBrandName+' | '+item.goodsName+ ' | '+item.factoryNo}}</p>
            </div>
          </div>
        </div>
        <div class="empty" v-if="list.length===0">请先扫描库存码</div>
      </div>
    </div>
    <div class="notarize">
      <button @click="scan()">扫描</button>
      <button @click="inHouse()">确认入库</button>
    </div>
    <nav-bar></nav-bar>
  </div>
</template>

<script>
import { wxScan,getGoodsSkuDetailByStoreNo,submitPutStorage} from '../../service/ware'
import navBar from '@/components/NavBar';
import navTop from '@/components/NavTop';
import {Toast} from "vant";
export default {
  data(){
    return{
      shelfCode:'请扫码货架码',
      list:[],
      storeNos:[],//库存编码
    }
  },
  components:{
    navBar,
    navTop
  },
  async mounted() {
    //测试数据
    //this.shelfCode ="HJXM1A02";
    //this.getDetail('CG2021121305-XMJFC1-BZ-035');
  },
  methods:{
    async wxAuthConfig(){
      let params ={
        url:location.href
      }
      Toast.loading({
        message: '加载中...',
        forbidClick: true
      });
      let _this = this;
      const { data } = await wxScan(params)
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: data.appId, // 必填，公众号的唯一标识
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.nonceStr, // 必填，生成签名的随机串
        signature: data.signature,// 必填，签名，见附录1
        jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
      });
      Toast.clear();
      wx.ready(function () {
        wx.scanQRCode({
          needResult:1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
          scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有
          success: function (res) {
            var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
            if(result.includes('HJ')&&!result.includes('CG')){
              _this.shelfCode = result;
            }else if(result.includes('CG')){
              _this.getDetail(result);
            }else{
              Toast({message:"扫描错误，请重新扫描！",duration:3000})
            }
          }
        });
      })
      wx.error(function(){
         location.reload();
      })
    },
    clear(str){
      if(str==="HJ"){
         this.shelfCode = "请扫码货架码";
      }else{
         this.storeNos = [];
         this.list = [];
      }
    },
    jump(){
      this.$router.push({path:'/success',query:{str:'入库'}})
    },
    scan(){
      this.wxAuthConfig();
    },
    async getDetail(storeNo){
      if(this.storeNos.includes(storeNo)){
        Toast({message:"您已经扫描过了，不需要再扫了！",duration:2500})
        return;
      }
      Toast.loading({
        message: '加载中...',
        forbidClick: true
      });
      let param ={
        storeNo:storeNo
      };
      const res = await getGoodsSkuDetailByStoreNo(param);
      Toast.clear();
      if(res.code===0){
        this.list.push(res.data);
        this.storeNos.push(res.data.storeNo);
      }else{
        Toast({message:"扫描错误，请重新扫描！",duration:3000})
      }
    },
    async inHouse(){
      if(!this.shelfCode.includes('HJ')||this.storeNos.length===0){
        Toast("请您先进行扫描");
        return;
      }
      Toast.loading({
        message: '入库中...',
        forbidClick: true
      });
      if(this.storeNos.length===0){
        Toast("请完成扫描");
        return;
      }
      let param ={
        shelvesCode:this.shelfCode,
        storeNos:this.storeNos.join(',')
      };
      const res = await submitPutStorage(param);
      Toast.clear();
      if(res.code===0){
        Toast.success({message:"入库成功",duration:2000})
        this.list = [];
        this.$router.push({path:'/success',query:{str:'入库'}})
      }else{
        Toast.fail({message:res.message+",请重新尝试",duration:3000})
      }
    }
  }
}
</script>
<style lang="less" scoped>
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@import '../../common/style/mixin';
.notarize{
  position: fixed;
  bottom: 160px;
  width: 534px;
  left: 50%;
  transform: translateX(-50%);
  button{
    height: 80px;
    border: 2px solid #3D69E1;
    border-radius: 40px;
    opacity: 1;
  }
  button:nth-of-type(1){
    width: 200px;
    background: #E7EBF8;
    color: #3D69E1;
    margin-right: 134px;
  }
  button:nth-of-type(2){
    width: 200px;
    background: #3D69E1;
    color: #FFFFFF;
  }
}
.content{
  padding: 48px 60px 0 64px;
  .content-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .content-title-left{
      height: 42px;
      font-size: 32px;
      font-family: Yu Gothic UI;
      font-weight: bold;
      color: #000000;
      opacity: 1;
    }
    .content-title-right{
      button{
        width: 136px;
        height: 60px;
        border-radius: 40px;
        opacity: 1;
      }
      button:nth-of-type(1){
        background: #B74134;
        color: #fff;
      }
    }
  }
  .content-center{
    margin-top: 50px;
    .content-text{
      height: 600px;
      overflow-y: auto;
      margin-top: 44px;
      }
   }
  .content-text-item{
    padding: 18px;
    display: flex;
    align-items: center;
    border-bottom: 2px solid #F4F4F4;
    }
  }
  .empty{
    margin-top: 24px;
    text-align: center;
    color: #9FA2A8;
    font-weight: 400;
    font-size: 24px;
    padding: 18px;
    align-items: center;
  }
  .Shelf-code{
    margin-top: 20px;
    height: 80px;
    background: #F4F4F4;
    border: 2px solid #F4F4F4;
    opacity: 1;
    border-radius: 0px;
    padding: 20px;
    font-size: 28px;
    font-family: Yu Gothic UI;
    font-weight: bold;
    color: #393C41;
    opacity: 1;
  }
  .active{
    .index{
      background: #9FA2A8;
      border: 2px solid #9FA2A8;
      opacity: 1;
    }
  }
.index{
  width: 40px;
  height: 40px;
  background: #000000;
  color: #FFFFFF;
  text-align: center;
  line-height: 40px;
  align-items: center;
  margin-right:30px;
  opacity: 1;
}
.commodity{
  text-align: center;
  .serial{
    font-size: 28px;
    font-family: Yu Gothic UI;
    font-weight: bold;
    color: #393C41;
    opacity: 1;
    text-align: left;
  }
  p:nth-of-type(2){
    text-align: left;
    font-size: 24px;
    font-family: Yu Gothic UI;
    font-weight: 400;
    color: #393C41;
    opacity: 1;
  }
}
.hint{
  display: flex;
}

</style>
