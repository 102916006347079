<template>
  <div class="order-box">
    <van-nav-bar
      title="挪库记录"
      left-text=""
      left-arrow
      @click-left="onClickLeft"
    />
    <van-cell-group border >
      <van-cell title="盘库时间" :value="content.createTime"  />
      <van-cell title="操作人" :value="content.checkUserName"  />
      <van-cell title="仓库" :value="content.storeHouseName" />
    </van-cell-group>
    <div style="height: 25px"></div>
    <h3>盘库数据</h3>
    <div class="content-data">
      <div class="content-data-four">
        <div>
          <div>{{content.storeNum}}</div>
          <div>库存数</div>
        </div>
        <div>
          <div>{{content.scanNum}}</div>
          <div>已扫描</div>
        </div>
        <div>
          <div>{{content.errorNum}}</div>
          <div>异常库存</div>
        </div>
      </div>
    </div>
    <h3>异常处理</h3>
    <div class="content-error" v-for="(item,index) in resultErrorList" :key="index" >
      <div class="content-header">
        <div class="left">{{item.skuGoodsName+"|a"+item.storeNo}}</div>
        <div class="right">{{item.dealStatus == 1 ? '待处理' : '已处理'}}</div>
      </div>
      <div class="content-detail">
        <p>异常类型： {{item.errorName}}</p>
        <p>库存状态： {{item.storeStatusName}}</p>
        <p>处理流程： {{item.dealWay}}</p>
        <p>处理结果：{{item.dealResult}} </p>
      </div>
    </div>
    <div class="empty" v-if="resultErrorList.length===0">暂无数据</div>
  </div>
</template>

<script>
import {getGoodsCheckEventErrorByPage,getStoreDataOnline} from '../../service/inventory'
import navTop from '@/components/NavTop';
import {Toast} from "vant";
import {getMoveGoodsByPage} from "@/service/inventory";
export default {
  data(){
    return{
      shelfCode:'请扫码货架码',
      list:[],
      storeNos:[],//库存编码
      active:0,
      loading:false,
      finished: true,
      content:{},
      dataOnline:{},
      resultErrorList:[]
    }
  },
  async mounted() {
    this.content = JSON.parse(localStorage.getItem('inventoryRecord'));
    this.getGoodsCheckEventErrorByPage({checkEventId:this.$route.query.checkEventId});
    this.getStoreDataOnline();
  },
  methods:{
    onClickLeft(){
      this.$router.go(-1);
    },
    async getStoreDataOnline(){
      const res = await getStoreDataOnline({checkEventId:this.$route.query.checkEventId});
      if(res.code===0){
        this.dataOnline =res.data;
      }else{
        Toast({message:res.message,duration:3000})
      }
    },
    async getGoodsCheckEventErrorByPage(param){
      const res = await getGoodsCheckEventErrorByPage(param);
      if(res.code===0){
        this.resultErrorList=res.data.list;
      }else{
        Toast({message:res.message,duration:3000})
      }
    },
  }
}
</script>
<style lang="less" scoped>

@import '../../common/style/mixin';
.empty{
  height: 100px;
  text-align: center;
}
h3{
  margin-left: 30px;
}
.tabs{
  margin-top: 20px;
}
.list{
  .header{
    font-size: 26px;
     margin: 0 30px;
     display: flex;
     flex-flow:row nowrap;
     background: #ececec;
     color: #3D69E1;
     height: 70px;
     align-items: center;

      .left{
        width: 60%;
      }
      .right{
        text-align: right;
        flex: 1;
      }
  }
  .content{
    margin: 0 30px;
    display: flex;
    flex-flow:row nowrap;
    height: 120px;
    align-items: center;
    border-bottom: 1px solid #ececec;
    .left{
      width: 60%;
    }
    .right{
      text-align: right;
      flex: 1;
    }
  }
}
.content-data{
  padding: 0 30px;
  .content-data-four{
    border:1px solid #ececec;
    padding: 20px;
    font-size: 26px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    text-align: center;
    div{
      div:nth-of-type(2){
        margin-top: 15px;
      }
    }
  }
}
.content-error{
  margin: 10px 30px;
  padding-top: 15px;
  border: 1px solid #ececec;
  .content-header{
    margin: 0 30px;
    display: flex;
    flex-flow:row nowrap;
    height: 120px;
    align-items: center;
    border-bottom: 1px solid #ececec;
    word-break: break-all;
    .left{
      width: 60%;
    }
    .right{
      text-align: right;
      flex: 1;
    }
  }
  .content-detail{
    margin: 0 30px;
    padding-top: 10px;
    p{
      line-height: 50px;
    }
  }
}
</style>
